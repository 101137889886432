.cart-sidebar-container {
  padding: 20px;
}

.cart-sidebar-container .empty-cart {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-sidebar-container .empty-cart img {
  width: 100%;
}

.cart-sidebar-container .empty-cart p {
  font-size: 22px;
  font-weight: bolder;
  color: var(--primary-color);
  text-align: center;
}

.cart-sidebar-container .empty-cart span {
  color: var(--sub-text-color);
  font-size: 1.555rem;
  text-align: center;
  font-weight: 600;
}

.cart-sidebar-container .empty-cart button {
  margin-top: 20px;
  background: var(--secondary-color);
  padding: 8px 15px;
  color: var(--text-field-color);

  font-size: 1.555rem;
  border-radius: 5px;
}

.cart-sidebar-container .cart-sidebar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 8px 16px;
  /* position: fixed;
    top: 0; */
}

.cart-sidebar-container .cart-sidebar-header .close-canvas {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 2.8125rem;
  width: 3.375rem;
  height: 3.375rem;
  color: var(--text-field-color);
  background: none;
  border-radius: 50%;
}

.cart-sidebar-container .cart-sidebar-header h5 {
  font-size: 2.435rem;

  font-weight: 900;
}

.cart-sidebar-container .cart-sidebar-product {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.cart-sidebar-container .cart-sidebar-product .products-header {
  background: var(--secondary-color);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 15px;
  margin-top: 20px;
}

.cart-sidebar-container .cart-sidebar-product .products-header span {
  color: var(--text-field-color);
  font-size: 1.855rem;
}

.cart-sidebar-container .cart-sidebar-product .products-container {
  display: block;
  margin-top: 20px;
  overflow-y: auto;
  scrollbar-width: 0;
  flex-grow: 1;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container::-webkit-scrollbar {
  width: 0;
}

.cart-sidebar-container .cart-sidebar-product .products-container .cart-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  width: 100%;
  margin: 10px 0;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .image-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 3px;
  height: fit-content;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .image-container
  img {
  height: 100px;
  width: 100px;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .product-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .product-details
  span {
  font-size: 1.855rem;
  font-weight: 800;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .product-details
  .selected-variant-cart {
  font-size: 14px;
  font-weight: 400;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .product-details
  .counter {
  height: 27px;
  border: 1px solid var(--secondary-color);
  display: flex;
  width: 100px;
  align-items: center;
  border-radius: var(--bd-radius-5);
  background: var(--secondary-color-light);
  justify-content: space-between;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .product-details
  .counter
  button {
  height: 100%;
  width: 20px;
  font-size: 1.755rem;
  background: var(--secondary-color);
  /* border-radius: 5px; */
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .left-wrapper
  .product-details
  .counter
  span {
  font-size: 1.555rem;
  font-weight: 500;
  padding: 0 12px;
  color: var(--secondary-color);
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .cart-card-end {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  align-items: flex-end;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .cart-card-end
  span {
  color: var(--secondary-color);
  font-size: 1.855rem;
  display: flex;
  align-items: center;
}

.cart-sidebar-container
  .cart-sidebar-product
  .products-container
  .cart-card
  .cart-card-end
  .remove-product {
  color: var(--text-field-color);
  font-size: 14px;
  background: var(--bs-red);
  padding: 4px 12px;
  border-radius: var(--bd-radius-5);
  width: 100%;
}

.cart-sidebar-container .cart-sidebar-footer {
  font-size: 1.855rem;
  font-weight: bolder;
}

.cart-sidebar-container .cart-sidebar-footer .heading {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.cart-sidebar-container .cart-sidebar-footer .total-amount span {
  color: var(--secondary-color);
  margin-left: 2px;
}

.cart-sidebar-container .cart-sidebar-footer .summary {
  padding: 5px 0;
  font-size: 18px;
  font-weight: 900;
  border-bottom: 1px solid #ddd;
}

.cart-sidebar-container .cart-sidebar-footer .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 30px;
}

.cart-sidebar-container .cart-sidebar-footer .button-container button {
  padding: 5px 15px;
  text-decoration: none;

  border-radius: var(--bd-radius-5);
  font-weight: bolder;
}

.cart-sidebar-container .cart-sidebar-footer .button-container .view-cart {
  background: none;
  border: 1px solid #ddd;
  color: var(--primary-color);
}

.cart-sidebar-container .cart-sidebar-footer .button-container .checkout {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--text-field-color);
}

.cart-sidebar-footer .promo-code {
  background-color: var(--secondary-color-light);
  border: 1px dashed var(--secondary-color);
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: initial;
}

.cart-sidebar-footer .promo-code .promo-remove {
  color: var(--bs-red);
  cursor: pointer;
}
.cart-sidebar-footer .promo-wrapper .promo-container .promo-button {
  background-color: var(--secondary-color);
  color: white;
  font-size: 14px;
  font-weight: 700;
  border-radius: var(--bd-radius-5);
}
.viewcart {
  min-height: 75vh;
}

.view-cart-container {
  padding: 30px 0;
}

@media (max-width: 980px) {
  .view-cart-container .container .row {
    flex-direction: column;
    gap: 20px;
  }

  .view-cart-container .viewcart-product-wrapper {
    width: 100%;
  }

  .view-cart-container .billing {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .view-cart-container .billing .promo-section,
  .view-cart-container .billing .cart-summary-wrapper {
    width: 100%;
  }
}
@media (max-width: 430px) {
  .view-cart-container .billing {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

#viewcart .cover {
  height: 200px;
}

#viewcart .cover .title {
  position: relative;
  bottom: 50%;
  text-align: center;
}

#viewcart .cover .title h3 {
  font-size: 2.385rem;
  font-weight: 800;
  color: var(--text-field-color);
}

#viewcart .cover .title span {
  font-size: 1.985rem;
  font-weight: 600;
  color: var(--text-field-color);
}

#viewcart .cover .title span.active {
  color: var(--secondary-color);
}

#viewcart .cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  background-position: center center;
}

.view-cart-container .viewcart-product-wrapper {
  flex-grow: 1;
  padding: 20px;
  background: var(--second-cards-color);
  border-radius: 8px;
  overflow-x: scroll;
}

.view-cart-container .viewcart-product-wrapper .product-heading h3 {
  font-size: 3.755rem;
  font-weight: bolder;
}

.view-cart-container .viewcart-product-wrapper .product-heading span {
  font-size: 1.755rem;
  font-weight: 600;
}

.view-cart-container .viewcart-product-wrapper .product-heading .title {
  color: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper .products-table thead {
  background: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper .products-table thead tr th {
  color: #000;
  font-size: 1.755rem;

  padding: 8px 15px;
}

.view-cart-container
  .viewcart-product-wrapper
  .products-table
  thead
  tr
  th.first-column,
.view-cart-container .viewcart-product-wrapper tbody tr th.first-column {
  padding: 8px 25px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.view-cart-container
  .viewcart-product-wrapper
  .products-table
  thead
  tr
  th.last-column,
.view-cart-container .viewcart-product-wrapper tbody tr th.last-column {
  padding: 8px 25px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.view-cart-container .viewcart-product-wrapper tbody tr th {
  font-size: 14px;

  font-weight: 600;
  padding: 8px 15px;
}

.view-cart-container
  .viewcart-product-wrapper
  .products-table
  .products-image-container {
  display: flex;
  gap: 30px;
}

.view-cart-container
  .viewcart-product-wrapper
  .products-table
  .products-image-container
  .image-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 3px;
  height: 100px;
  width: 100px;
}

.view-cart-container
  .viewcart-product-wrapper
  .products-table
  .products-image-container
  .image-container
  img {
  height: 100%;
  width: 100%;
}

.view-cart-container .viewcart-product-wrapper tbody .price {
  color: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper tbody .quantity > div {
  border-radius: var(--bd-radius-5);
  border: 1px solid var(--secondary-color);
  display: flex;
  width: fit-content;
  background: var(--secondary-color-light);
}

.view-cart-container .viewcart-product-wrapper tbody .quantity button {
  background: var(--secondary-color);
  /* border-radius: 5px; */
  padding: 4px 6px;
}

.view-cart-container .viewcart-product-wrapper tbody .quantity span {
  padding: 5px 15px;
  color: var(--secondary-color);
}

.view-cart-container .viewcart-product-wrapper tbody .remove button {
  background: none;
}

.view-cart-container .cart-summary-wrapper {
  background: var(--text-field-color);
  border-radius: 8px;
  height: fit-content;
  flex-grow: 0.3;
}

.view-cart-container .cart-summary-wrapper .heading,
.billing .promo-section .heading {
  font-size: 1.935rem;
  font-weight: bolder;
  text-align: center;
  background: var(--secondary-color);
  padding: 5px;
  border-radius: 8px 8px 0px 0px;
}

.billing .promo-section .promo-wrapper {
  padding: 20px;
  font-size: 1.555rem;
  background-color: var(--second-cards-color);
  font-weight: 700;
}

.billing .promo-section .promo-wrapper .promo-container .promo-button {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing .promo-section .promo-wrapper .promo-container .promo-button button {
  background-color: var(--secondary-color);
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  color: var(--bs-white);
  font-weight: 700;
}

.billing .promo-section .promo-wrapper .promo-container .promo-code {
  background-color: var(--secondary-color-light);
  border: 1px dashed var(--secondary-color);
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: initial;
}

.billing
  .promo-section
  .promo-wrapper
  .promo-container
  .promo-code
  .promo-name {
  font-weight: 500;
}

.billing
  .promo-section
  .promo-wrapper
  .promo-container
  .promo-code
  .promo-remove {
  color: red;
  font: 600 14px;
  align-self: center;
  cursor: pointer;
}

.view-cart-container .cart-summary-wrapper .heading span,
.billing .promo-section .heading span {
  color: var(--text-field-color);
}

.view-cart-container .cart-summary-wrapper .summary {
  padding: 20px;
  font-size: 1.555rem;
  font-weight: 700;
}

.view-cart-container .cart-summary-wrapper .summary > div {
  padding: 5px;
}

.view-cart-container .cart-summary-wrapper .summary .total {
  margin-top: 5px;
  border-top: 1px solid #eee;
}

.view-cart-container .cart-summary-wrapper .summary .total .total-amount,
.view-cart-container .cart-summary-wrapper .summary .total .total-amount span {
  color: var(--secondary-color);
  margin: 0 3px;
}

.view-cart-container .cart-summary-wrapper .summary .button-container {
  /* background: var(--secondary-color); */
  /* border-radius: 8px; */
  /* margin-top: 30px; */
  text-align: center;
}
.view-cart-container .cart-summary-wrapper .summary .button-container button {
  color: var(--bs-white);
  background-color: var(--secondary-color);
  font-weight: 700;
  padding: 8px;
  border-radius: var(--bd-radius-5);
  font-size: 14px;
}
.view-cart-container .cart-summary-wrapper .summary .button-container a {
  padding: 5px 15px;
  text-decoration: none;

  border-radius: 8px;
  font-weight: bolder;
  color: var(--text-field-color);
}

.view-cart-container .empty-cart {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.view-cart-container .empty-cart img {
  width: 350px;
}

.view-cart-container .empty-cart p {
  font-size: 2.555rem;
  font-weight: bolder;
  color: var(--primary-color);
  text-align: center;
}

.view-cart-container .empty-cart span {
  color: var(--sub-text-color);
  font-size: 1.555rem;
  text-align: center;
  font-weight: 600;
}

.view-cart-container .empty-cart button {
  margin-top: 20px;
  background: var(--secondary-color);
  padding: 8px 15px;
  color: var(--text-field-color);

  font-size: 1.555rem;
  border-radius: 5px;
}

.view-cart-container .cart-summary-wrapper {
  background: var(--second-cards-color);
  border-radius: 8px;
}

@media (max-width: 48rem) {
  .view-cart-container {
    flex-direction: column;
  }

  .view-cart-container
    .viewcart-product-wrapper
    .products-table
    .products-image-container {
    gap: 10px;
  }

  .view-cart-container
    .viewcart-product-wrapper
    .products-table
    .products-image-container
    .image-container {
    height: 40px;
    width: 40px;
  }

  .view-cart-container
    .viewcart-product-wrapper
    .products-table
    .products-image-container
    select {
    margin-top: 5px;
  }

  .view-cart-container
    .viewcart-product-wrapper
    .products-table
    thead
    tr
    th.first-column,
  .view-cart-container .viewcart-product-wrapper tbody tr th.first-column {
    padding: 8px 5px;
  }

  .view-cart-container
    .viewcart-product-wrapper
    .products-table
    thead
    tr
    th.last-column,
  .view-cart-container .viewcart-product-wrapper tbody tr th.last-column {
    padding: 8px 5px;
  }

  .view-cart-container .viewcart-product-wrapper tbody .quantity button {
    padding: 4px;
  }

  .view-cart-container .viewcart-product-wrapper tbody .quantity span {
    padding: 4px;
  }

  .view-cart-container .viewcart-product-wrapper .products-table thead tr th {
    padding: 8px 6px;
  }

  .view-cart-container .viewcart-product-wrapper tbody tr th {
    padding: 10px 6px;
  }
}

/* Promo Offcanvas  */

.promo-sidebar-container {
  font-size: 14px;
}

/* .offcanvas {
    --bs-offcanvas-width:500px !important; 
} */
.promo-sidebar-container .promo-sidebar-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  font-size: 22px !important;
  font-weight: 600 !important;
}

.promo-sidebar-container .promo-sidebar-body {
  padding: 20px;
  margin-top: 30px;
}

.promo-sidebar-container .promo-sidebar-body .promo-card {
  border-bottom: 1px solid #ddd;
  padding: 20px 0 20px 0;
}

.promo-sidebar-container .promo-sidebar-body .promo-card .promo-card-e1 {
  display: flex;
  justify-content: space-between;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-img {
  width: 80px;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-img
  img {
  width: 100%;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-details {
  display: flex;
  align-items: center;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-details
  .promo-name {
  padding: 10px 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-details
  .promo-name
  .promo-code {
  font-weight: bold;
  font-size: 16px;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-details
  .promo-name
  .promo-discount {
  font-size: 18px;
  font-weight: bolder;
  color: var(--secondary-color);
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-apply {
  margin: auto 0;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-apply
  span {
  border-radius: 5px;
  padding: 2px 8px;
  font-size: 15px;
  font-weight: normal;
  color: var(--second-cards-color);
  background-color: var(--secondary-color) !important;
  transition: all 1s ease-in-out !important;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e1
  .promo-apply
  span:hover {
  background-color: var(--secondary-color-dark) !important;
  color: #000 !important;
}

.promo-sidebar-container .promo-sidebar-body .promo-card .promo-card-e2 {
  display: flex;
  flex-direction: column;
}

.promo-sidebar-container
  .promo-sidebar-body
  .promo-card
  .promo-card-e2
  .promo-description {
  font-weight: bold;
  font-size: 14px;
  color: #8b8b8b !important;
}
