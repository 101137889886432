.category_section {
  margin: 50px auto;
  /* padding: 0 !important; */
}

.category_section .container {
  border-radius: 8px;
}

#allcategories .container .back-button {
  cursor: pointer;
}

.category_section_header {
  margin-bottom: 25px;
  background-color: var(--second-cards-color);
  padding: 15px;
  border-radius: 5px;
}
[dir="rtl"] .category_section_header .see_category_arrow {
  transform: rotate(180deg);
}
[dir="rtl"] .category_section_header .next-arrow-category {
  order: 0;
}
[dir="rtl"] .category_section_header .prev-arrow-category {
  order: 1;
}
.category-container {
  margin: 0 10px 0 0;
}

.slick-prev-category,
.next-arrow-category {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  left: auto !important;
  /* additional styling if needed */
}

.category-container .card {
  /* padding: 3em 1.5em; */
  border-radius: 8px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  /* transition: transform 1.2s ease-in-out; */
}

.category-container .card:hover {
  transform: none !important;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--secondary-color);
}

.card:hover .card-title {
  color: var(--secondary-color);
}

.category-container .card:hover .category_image {
  /* transform: scale(1.07); */
}

.category-container .card .category_image {
  /* width: 120px; */
  /* border-radius: 100%; */
  margin: 0 auto;
  /* padding: 8px; */
  border-radius: 7px 7px 0px 0px;
  /* border: 1px solid #ddd; */
  /* height: 120px; */
  /* margin-bottom: 15px; */
  /* transition: transform 0.7s ease-in-out; */
}

.category-container .card .card-body {
}

.category-container .card .card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category_button:hover {
  background: var(--secondary-color);
  color: var(--text-field-color);
}
.category_button {
  border-radius: 8px;
  font-size: 14px;
  /* padding: 8px 16px; */
  padding: 7px;
  text-decoration: none;
  background-color: var(--secondary-color);
  text-align: center;
  color: var(--text-field-color) !important;
}

/* #allcategories{
    display: flex;
    flex-direction: column;
} */

#allcategories .cover {
  height: 200px;
}

#allcategories .cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  background-position: center center;
}

#allcategories .cover .page-heading {
  position: relative;
  bottom: 100px;
  text-align: center;
}

#allcategories .cover .page-heading h5 {
  color: var(--text-field-color);
  font-size: 3rem;
}

#allcategories .cover .page-heading p {
  color: var(--text-field-color);
  font-size: 2rem;
}

#allcategories .cover .page-heading p span {
  color: var(--secondary-color);
}

/* #allcategories .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
} */

#allcategories .content .card {
  border-radius: 8px;
  /* padding-top: 10px; */
  cursor: pointer;
}
#allcategories.home .content .card {
  border-radius: 8px;
  /* border: none; */
  /* border-top: none; */
  /* padding-top: 10px; */
  margin: 8px;
}

#allcategories .content .card img {
  width: 100%;
  /* height: 26rem; */
  border-radius: 8px 8px 0px 0px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

#allcategories .content .card .card-body {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  /* background-color: var(--secondary-color); */
}

#allcategories .content .card:hover {
  outline: var(--secondary-color);
  transform: none !important;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color) !important;
}

#allcategories .content .card .card-body:hover p {
  /* background-color: var(--secondary-color-light);
  color: var(--text-field-color) !important; */
  color: var(--secondary-color) !important;
}

#allcategories .content .card:hover .card-body p {
  /* background-color: var(--secondary-color) !important; */
  color: var(--secondary-color) !important;
}

#allcategories .content .card .card-body p {
  /* font-size: 1.745rem;
    font-weight: bolder;
    color: #fff;
    text-align: center;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  display: block;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
