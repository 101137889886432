.location h5 {
  font-size: 2.2rem;
  font-weight: bolder;
}

.location .header button {
  background: none !important;
  font-size: 3rem !important;
}

.location .body .location-logo {
  height: 100px !important;
  margin: 30px !important;
}

.location .body span {
  font-size: 1.5rem;
  color: var(--sub-text-color);
  text-align: center;
}

.location .body button {
  margin-top: 30px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
  background-color: var(--second-cards-color);
  width: 100%;
  color: var(--primary-color);
  font-size: 1.845rem;
}

/* .location .body button.gm-svpc {
    display: none;
} */

.location .body button:hover {
  background: var(--secondary-color);
  /* color: var(--text-field-color); */
}

.location .body .oval-continer {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.location .body .oval-continer .oval {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
}

.location .body .oval-continer .oval .separator-text {
  position: relative;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 50%;
}

.location .body .oval-continer .oval .separator-text::before {
  right: 100%;
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #ccc;
  width: 7pc;
  margin: 0 7px;
}

.location .body .oval-continer .oval .separator-text .or {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  text-align: center;
}

.location .body .oval-continer .oval .separator-text::after {
  left: 100%;
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #ccc;
  width: 7pc;
  margin: 0 7px;
}

.location .body .input-container {
  width: 100%;
}

.location .body input[type="text"] {
  width: 100%;
  background: var(--body-background);
  border: 1px solid var(--container-bg);
  border-radius: 5px;
  padding: 6px;
  font-size: 14px;
}

.location .map-content {
  display: inline-flex;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.location .map-content-p,
.location .map-content button {
  font-size: 2rem;
}

/* z-index problem of munualy text select location */
.pac-container {
  z-index: 1055 !important;
}

.ant-modal .ant-modal-footer {
  display: none !important;
}

.ant-modal .ant-modal-close-x {
  display: none !important;
}

/* .current-location-click {
  position: relative;
  left: 370px;
  top: 10px;
  z-index: 10 !important;
  margin-top: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: var(--bs-white);
  color: var(--primary-color);
  width: 40px !important;
  height: 40px;
  font-size: 1.845rem;
} */

.current-location-click:hover {
  background: var(--bs-white) !important;
}

@media (max-width: 400px) {
  .current-location-click {
    left: 240px;
  }
}

@media (max-width: 320px) {
  .current-location-click {
    left: 207px;
    top: 53px;
  }
}
.mapWrapper {
  position: relative !important;
}
.current-location-click {
  position: absolute;
  z-index: 5;
  top: 10px;
  width: 40px;
  height: 40px;
  left: auto;
  right: 55px;
  background: var(--bs-white);
  z-index: 10 !important;
  margin-top: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: var(--bs-white);
  color: var(--primary-color);
  width: 40px !important;
  height: 40px;
  font-size: 1.845rem;
}
.current-location-logo {
  width: 20px;
  height: 20px;
}

@media (max-width: 350px) {
  .current-location-click {
    top: 55px;
    right: 10px;
  }
}
